import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import { localeTagSelector } from 'shared/app/state/selectors/locales';

import { formMessages } from 'shared/app/messages';
import messages from './messages';
import { useSelector } from 'react-redux';

const canadaSettings = {
  length: 7,
  placeHolder: 'A1A 1A1',
};

const localePostalCodeSettings = {
  'en-CA': canadaSettings,
  'en-US': {
    length: 5,
    placeHolder: '#####',
  },
  'fr-CA': canadaSettings,
};

const PostalCodeField = ({ postalCode, numeric, ...rest }) => {
  const currentLocaleTag = useSelector(localeTagSelector);
  const { formatMessage } = useIntl();

  const errorMessage = formatMessage(
    postalCode.errorMessage || messages.postalCodeError
  );
  return (
    <Field
      {...postalCode.input}
      autoComplete={numeric ? 'on' : 'postal-code'}
      data-e2e="postalCode"
      error={shouldDisplayError(postalCode)}
      errorMessage={errorMessage}
      maxLength={localePostalCodeSettings[currentLocaleTag].length}
      maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
      placeHolder={localePostalCodeSettings[currentLocaleTag].placeHolder}
      type={numeric ? 'tel' : 'text'}
      {...rest}
    >
      {formatMessage(messages.postalCode)}
    </Field>
  );
};

PostalCodeField.propTypes = {
  postalCode: PropTypes.object,
  numeric: PropTypes.bool,
};

PostalCodeField.defaultProps = {
  numeric: true,
  postalCode: {
    input: {
      id: 'postalCode',
      value: '',
    },
  },
};

export default PostalCodeField;
