import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import rootReducers from '../../state/reducers';
import {
  extractAsArray,
  extractByAppName,
} from '../../utils/sub-app-extractors';
import createAsyncCountReducer from '../create-async-count-reducer';
import createRoutesReducer from '../create-routes-reducer';
import createModalReducer from '../create-modal-reducer';

// Preserve initial state for not-yet-loaded reducers
// http://nicolasgallagher.com/redux-modules-and-code-splitting/
export const preserveInitialReducersState = (reducers, data) => {
  const reducerNames = Object.keys(reducers);
  const noOps = {};
  Object.keys(data).forEach((item) => {
    if (reducerNames.indexOf(item) === -1) {
      noOps[item] = (state = null) => state;
    }
  });
  return {
    ...noOps,
    ...reducers,
  };
};

export const getReducers = (apps, routes, history) => {
  // merge options provided by individual sub-apps
  const asyncActionsArray = extractAsArray(apps, 'asyncActions');
  const modalsOpenedByUrlHashArray = extractAsArray(
    apps,
    'modalsOpenedByUrlHash'
  );

  const { routerReducer } = createReduxHistoryContext({ history });

  return Object.assign(
    {
      asyncCount: createAsyncCountReducer(asyncActionsArray),
      locale: (state = {}) => state,
      previousAction: (state, action) => action,
      router: routerReducer,
      routes: createRoutesReducer({ routes, location: history.location }),
      modals: createModalReducer(modalsOpenedByUrlHashArray),
    },
    rootReducers,
    extractByAppName(apps, 'reducer')
  );
};

export const getStoreReducer = ({ data, apps, routes, history }) => {
  const rawReducers = getReducers(apps, routes, history);
  const reducersWithInitialState = preserveInitialReducersState(
    rawReducers,
    data
  );
  const combinedReducers = combineReducers(reducersWithInitialState);
  return combinedReducers;
};
