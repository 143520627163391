import React, { forwardRef } from 'react';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import magicWandPath from '@starbucks-web/pattern-library/lib/icons/magic-wand';
import Frap from 'shared/app/components/frap-with-messages';

const MagicWandFrap = forwardRef(({ onClick, message, className }, ref) => (
  <Frap
    className={className}
    color="houseGreen"
    data-e2e="magic-wand-frap"
    onClick={onClick}
    ref={ref}
  >
    <span className="flex items-end">
      <Icon className="mr2 color-gold" path={magicWandPath} />
      {message}
    </span>
  </Frap>
));

export default MagicWandFrap;
