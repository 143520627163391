import { createSelector } from 'reselect';

import { configSelector } from './config';

export const globalNavMainNavLinksSelector = createSelector(
  configSelector,
  (config) => {
    return config?.globalNav?.mainNavLinks?.filter((mainNavItem) => {
      return mainNavItem;
    });
  }
);

export const globalNavAccountNavSelector = createSelector(
  configSelector,
  (config) => config?.globalNav?.accountNav
);
