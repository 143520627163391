'use strict';

module.exports = {
  ADD_DIGITAL_STORED_VALUE_CARD: 'add-digital-stored-value-card',
  ADD_PHYSICAL_STORED_VALUE_CARD: 'add-physical-stored-value-card',
  ADD_SINGLE_USE_BILLING_ADDRESS: 'add-single-use-billing-address',
  CREATE_ADDRESS: 'create-address',
  CREATE_PAYMENT_INSTRUMENT: 'create-payment-instrument',
  DELETE_PAYMENT_INSTRUMENT: 'delete-payment-instrument',
  CREATE_SINGLE_USE_APPLE_PAY_PAYMENT: 'create-single-use-apple-pay-payment',
  GET_ACCRUAL_EARN_RATES: 'get-accrual-earn-rates',
  GET_ONBOARDING_FLAGS: 'get-onboarding-flags',
  GET_PAYPAL_SETUP_TOKEN: 'get-paypal-setup-token',
  GET_STARPAY_WALLET: 'get-starpay-wallet',
  GET_STORED_VALUE_CARD_LIST: 'get-stored-value-card-list',
  GET_USER: 'get-user',
  GET_USER_MFA_FACTORS: 'get-user-mfa-factors',
  OPT_IN_USER_OFFER: 'opt-in-user-offer',
  RELOAD_STORED_VALUE_CARD: 'reload-stored-value-card',
  REMOVE_STORED_VALUE_CARD: 'remove-stored-value-card',
  REPORT_AND_REPLACE_LOST_STOLEN_SVC: 'report-and-replace-lost-stolen-svc',
  SEND_MFA_CODE_MUTATION: 'send-mfa-code-mutation',
  SET_PRIMARY_STORED_VALUE_CARD: 'set-primary-stored-value-card',
  STORED_VALUE_CARD_REALTIME_BALANCE: 'stored-value-card-realtime-balance',
  SET_PRIVACY_PERMISSIONS: 'set-privacy-permissions',
  GET_PRIVACY_PERMISSIONS: 'get-privacy-permissions',
  SET_TERMS_ACKNOWLEDGEMENT: 'set-terms-acknowledgement',
  GET_TERMS_ACKNOWLEDGEMENT: 'get-terms-acknowledgement',
  UPDATE_ADDRESS: 'update-address',
  UPDATE_ONBOARDING_FLAG: 'update-onboarding-flag',
  UPDATE_SVC_NICKNAME: 'update-svc-nickname',
  VERIFY_MFA_CODE_MUTATION: 'verify-mfa-code-mutation',
};
