import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

import OfflineIndicator from '@starbucks-web/pattern-library/lib/components/offline-indicator';
import { sharedNotificationMessages } from 'shared/app/messages/notifications';

import AsyncLoadingIndicator from './async-loading-indicator';
import ErrorNotification from './error-notification';
import GlobalBottomDrawer from './global-bottom-drawer';

/*
 * #notifications-target div is in the html payload
 * from the server. See src/shared/server/lib/stream-to-html/index.js
 * z-indexes are applied in the html to manage layering.
 * Notifications always need to be above any open modal layers.
 */
const notificationsTargetId = 'notifications-target';

const GlobalLayers = ({ routeOptions }) => {
  const { formatMessage } = useIntl();

  const notificationsTargetElement = document.getElementById(
    notificationsTargetId
  );

  return ReactDOM.createPortal(
    <Fragment>
      {/* Notifications should appear above any open modals */}
      <div className="relative" style={{ zIndex: 1 }}>
        {/* GlobalBottomDrawer contains Toast notifications plus Crust */}
        <GlobalBottomDrawer routeOptions={routeOptions} />
        <ErrorNotification />
      </div>
      {/*
            Make sure this is visible above any open modal layer
          */}
      <OfflineIndicator
        className="relative"
        label={formatMessage(sharedNotificationMessages.offlineIndicator)}
        style={{ zIndex: 3 }}
      />
      {/*
            Loading indicator has a z-index of 10 set directly in the pattern library. Would need to
            updated the pattern library component if we want this to be configurable, but it works as is
            with current layout needs.
          */}
      <AsyncLoadingIndicator />
    </Fragment>,
    notificationsTargetElement
  );
};

export default GlobalLayers;
