import { FEATURES } from 'shared/universal/optimizely-keys';
import { getOptimizelyFeatureFlag } from './get-optimizely-feature-flag';

export const getDemystifyMenuFeatureFlag = async () =>
  await getOptimizelyFeatureFlag(FEATURES.DEMYSTIFY_MENU);

export const getDemystifyMenuPdpFeatureFlag = async () =>
  await getOptimizelyFeatureFlag(FEATURES.DEMYSTIFY_MENU_PDP);

export const getDemystifyMenuPreviousFeatureFlag = async () =>
  await getOptimizelyFeatureFlag(FEATURES.DEMYSTIFY_MENU_PREVIOUS);
