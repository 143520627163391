import { useEffect } from 'react';

import { variables } from '@starbucks-web/pattern-library/lib/shared-variables';

const useFullPageNeutralBackgroundColor = () => {
  // Ensure the background color extends to full height of any content
  useEffect(() => {
    document.body.style.backgroundColor = variables.colorNeutralCool;
    // reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
};

export default useFullPageNeutralBackgroundColor;
