import React from 'react';
import { injectIntl } from 'react-intl';

import Carousel from '@starbucks-web/pattern-library/lib/components/carousel';

import { carouselControlAriaMessages as messages } from 'shared/app/messages/carousel-controls';

// Light carousel wrapper to keep all the intl definitions and toggle carousel features on/off
const CarouselWithMessages = ({ intl, forwardedRef, ...carouselProps }) => {
  const { formatMessage } = intl;
  const msg = {
    ariaDescription: (slideCount) =>
      formatMessage(messages.mainDescription, {
        slideCount,
      }),
    ariaLabel: formatMessage(messages.ariaLabel, {
      carouselLabel: carouselProps.carouselLabel,
    }),
    ariaLabelSlide: formatMessage(messages.ariaLabelSlide),
    ariaLabelNext: formatMessage(messages.nextButton),
    ariaLabelPrev: formatMessage(messages.prevButton),
    ariaLabelPages: (pageNumber) =>
      formatMessage(messages.pageButtons, { pageNumber }),
  };

  return <Carousel messages={msg} ref={forwardedRef} {...carouselProps} />;
};

export default injectIntl(CarouselWithMessages);
