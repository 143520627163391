import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { userMfaVerifiedPhoneSelector } from 'shared/app/bundles/user/state/selectors/mfa';

import MfaChallengeEnterCodeOverlayContainer from './container';

import messages from '../shared-messages';

const IMAGE_PATH = '/mfa/cell-phone.png';
const IMAGE_WIDTH = 168;
export const MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID =
  'MfaChallengeEnterCodeOverlayId';

const MfaChallengeEnterCodeOverlay = ({
  verificationType = 'secureSession',
  onCodeSuccess,
  onCodeError,
  phoneId,
  phoneNumber,
  phoneSmsState,
  username,
  password,
  closeCallback,
  closeProps,
}) => {
  const { formatMessage } = useIntl();

  const isSecureSessionVerificationType = verificationType === 'secureSession';
  const isPhoneVerificationType = verificationType === 'phoneVerification';
  const isSecureSessionOrPhoneVerificationType =
    isSecureSessionVerificationType || isPhoneVerificationType;

  const configImagesUrl = useSelector(configImagesUrlSelector);
  const userMfaVerifiedPhone = useSelector(userMfaVerifiedPhoneSelector);

  const phoneDetails = isSecureSessionVerificationType
    ? {
        id: userMfaVerifiedPhone?.id,
        phoneNumber: userMfaVerifiedPhone?.phoneNumber,
        phoneSmsState: userMfaVerifiedPhone?.phoneSmsState,
      }
    : {
        id: phoneId,
        phoneNumber,
        phoneSmsState,
      };

  return (
    <OverlayWithCloseMessage
      alignment="rightCrate"
      closeCallback={closeCallback}
      closeProps={closeProps}
    >
      <ContentColumn
        className="frapPadding size12of12"
        innerClassName="flex flex-column text-center"
        size="sm"
      >
        <ImageLoader
          alt=""
          className="self-center md-pt9"
          lazyLoad={{ enabled: false }}
          role="presentation"
          src={`${configImagesUrl}${IMAGE_PATH}`}
          width={`${IMAGE_WIDTH}`}
        />
        <Heading
          className="text-bold pt5 pb3"
          id={MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID}
          size="lg"
          tagName="h2"
        >
          {formatMessage(
            isSecureSessionOrPhoneVerificationType
              ? messages.verifyNumber
              : messages.enterMfaCodeHeading
          )}
        </Heading>
        <p className="pb4">
          {formatMessage(
            isSecureSessionOrPhoneVerificationType
              ? messages.verifyNumberSubHeading
              : messages.enterMfaCode,
            {
              phoneNumber: isSecureSessionOrPhoneVerificationType
                ? userMfaVerifiedPhone?.phoneNumber?.slice(-4) ||
                  phoneNumber.slice(-4)
                : phoneNumber,
            }
          )}
        </p>
        <MfaChallengeEnterCodeOverlayContainer
          data-e2e="mfaChallengeOverlay"
          onCodeError={onCodeError}
          onCodeSuccess={onCodeSuccess}
          password={password}
          phone={phoneDetails}
          username={username}
          verificationType={verificationType}
        />
      </ContentColumn>
    </OverlayWithCloseMessage>
  );
};

MfaChallengeEnterCodeOverlay.propTypes = {
  phoneId: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneSmsState: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  verificationType: PropTypes.string,
  onCodeSuccess: PropTypes.func,
  onCodeError: PropTypes.func,
};

export default MfaChallengeEnterCodeOverlay;
