import { defineMessages } from 'react-intl';

export const orderingMessages = defineMessages({
  orderPickupTypeInStore: {
    id: 'shared.orderPickupTypeInStore',
    defaultMessage: 'In-store pickup',
    description: 'label for in-store pickup type',
  },
  orderPickupTypeDriveThru: {
    id: 'shared.orderPickupTypeDriveThru',
    defaultMessage: 'Drive-thru pickup',
    description: 'label for drive-thru pickup type',
  },
  orderPickupTypeCurbside: {
    id: 'shared.orderPickupTypeCurbside',
    defaultMessage: 'Curbside pickup',
    description: 'label for curbside pickup type',
  },
  orderPickupTypeOutdoor: {
    id: 'shared.orderPickupTypeOutdoor',
    defaultMessage: 'Outdoor pickup',
    description: 'label for outdoor pickup type',
  },
  scheduledOrderingPickupTimeSlot: {
    id: 'shared.scheduledOrderingPickupTimeSlot',
    defaultMessage: 'Pickup between {timeSlot}',
    description:
      'order pickup time message for if scheduled ordering is enabled',
  },
});
