import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import { getFraudReputation } from 'shared/app/utils/iovation';

import { legacyBaseUrlSelector } from 'shared/app/state/selectors/locales';
import ReAuthForm from './re-auth-form';
import messages from './messages';
import { get } from 'lodash';
import { showEnterCodeOverlayForLogin as showEnterCodeOverlayForLoginAction } from 'shared/app/bundles/mfa/state/show-overlay-action-creators';
import redirectToSigninWithReturnUrl from 'shared/app/utils/redirect-to-signin-with-return-url';

import config from 'config';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
const OAUTH_MFA_CHALLENGE_ERROR_CODE = config.get(
  'universal.oauthMfaChallengeErrorCode'
);

const ReAuthFormContainer = ({
  legacyBaseUrl,
  onCancel,
  onSuccess,
  reAuthenticate,
  showEnterCodeOverlayForLogin,
}) => {
  const [loading, setLoading] = useState(false);
  const { openModal } = useModalContext();

  const fields = {
    password: {},
  };
  const formRef = useRef();
  const { formatMessage } = useIntl();

  const checkForMFAChallenge = (error) => {
    setLoading(false);

    const code = get(error, 'data.error');
    if (code === OAUTH_MFA_CHALLENGE_ERROR_CODE) {
      const {
        value: phoneId,
        phoneNumber,
        phoneSmsState,
      } = get(error, 'data.mfaChallengeMethods');

      showEnterCodeOverlayForLogin({
        phoneId,
        phoneNumber,
        phoneSmsState,
        openModal,
        onCodeSuccess: onSuccess,
      });
    } else {
      throw error;
    }
  };

  const handleSubmitError = (errorMessage) => {
    formRef.current.updateField({
      errorMessage,
      hasServerSideError: true,
      input: {
        name: 'password',
      },
    });
  };

  const handleSubmit = (fieldsState) => {
    const password = fieldsState.password;
    setLoading(true);
    getFraudReputation()
      .then((reputation) => reAuthenticate(password, reputation))
      .then(() => {
        // setTimeout avoids running into a racing condition
        // and allows the re-auth request to fully complete before
        // calling the onSuccess function.
        setTimeout(() => {
          onSuccess();
          setLoading(false);
        });
      })
      .catch(checkForMFAChallenge)
      .catch((err) => {
        switch (err.httpStatus) {
          case 400:
            handleSubmitError(formatMessage(messages.passwordError));
            break;
          default:
            redirectToSigninWithReturnUrl();
        }
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <FormContainer fields={fields} onSubmit={handleSubmit} ref={formRef}>
        <ReAuthForm
          legacyUrl={legacyBaseUrl}
          loading={loading}
          onCancel={onCancel}
        />
      </FormContainer>
    </React.Fragment>
  );
};

ReAuthFormContainer.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  reAuthenticate: PropTypes.func,
};

const select = (state) => ({
  legacyBaseUrl: legacyBaseUrlSelector(state),
});

const actions = {
  showEnterCodeOverlayForLogin: showEnterCodeOverlayForLoginAction,
};

export default connect(select, actions)(ReAuthFormContainer);
