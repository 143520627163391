import React from 'react';
import { useIntl } from 'react-intl';
import BottomSheet from '@starbucks-web/pattern-library/lib/components/bottom-sheet';

import { sharedCallsToAction } from 'shared/app/messages/ctas';

const BottomSheetWithMessages = ({ closeProps = {}, ...otherProps }) => {
  const intl = useIntl();

  return (
    <BottomSheet
      closeProps={{
        ariaLabel: intl.formatMessage(sharedCallsToAction.closeLabel),
        'data-e2e': 'closeBottomSheet',
        ...closeProps,
      }}
      data-e2e="bottomSheetContainer"
      {...otherProps}
    />
  );
};

export default BottomSheetWithMessages;
