/* eslint no-shadow: 0*/
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import config from 'config';
import { connect } from 'react-redux';
import ModalProvider from '@starbucks-web/pattern-library/lib/components/modal-provider';
import { Helmet } from 'react-helmet';

import { signedInSelector } from 'shared/app/bundles/user';
import {
  pathnameSelector,
  routeSelector,
} from 'shared/app/state/selectors/routes';
import { initIovation } from 'shared/app/utils/iovation/init-iovation';

import { doLogin as doLoginAction } from '../state/action-creators/app-lifecycle';
import {
  hamburgerNavIsOpenSelector,
  preventFocusTargetsSelector,
  preventScrollingSelector,
} from '../state/selectors/app-ui';

import ErrorBoundary from '../../components/error-boundary';
import MainContent from './layout/main-content';
import GlobalLayers from './global-layers';
import GlobalNav from './layout/global-nav';
import ShellSkipLink from '../../components/shell-skip-link';

export const App = ({
  authSatisfied,
  doLogin,
  hamburgerNavIsOpen,
  hideGlobalNav,
  nav,
  pathname,
  preventFocusTargets,
  preventScrolling,
  routeOptions,
  signedIn,
}) => {
  const hasGlobalNav = Boolean(!hideGlobalNav && nav?.globalNav?.component);
  const mainContentAllowFocus =
    !preventFocusTargets.content && !preventFocusTargets.header;
  const qualtricsEnabled = config.get('universal.qualtrics.enabled');

  const handlePreventScrolling = (preventScrolling) => {
    const scrollY = document.body.style.top;
    document.body.style.overflow = preventScrolling ? 'hidden' : 'unset';
    document.body.style.touchAction = preventScrolling ? 'none' : 'auto';
    document.body.style.top = preventScrolling ? `-${window.scrollY}px` : '';
    document.body.style.position = preventScrolling ? 'fixed' : '';
    document.body.style.width = preventScrolling ? '100%' : '';

    if (!preventScrolling) {
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  };

  useEffect(() => {
    initIovation();
  }, []);

  useEffect(() => {
    // scrollIntoView should be called whenever pathname changes,
    // though pathname is not needed in the effect.
    document.body.scrollIntoView();
  }, [pathname]);

  useEffect(() => {
    if (!authSatisfied) {
      doLogin();
    }
  }, [authSatisfied]);

  useEffect(() => {
    handlePreventScrolling(preventScrolling);
  }, [preventScrolling]);

  useEffect(() => {
    if (qualtricsEnabled) {
      // Used for Qualtrics survey metadata capture
      window.sessionStorage.setItem('isAuthenticated', signedIn);
    }
  }, [qualtricsEnabled, signedIn]);

  return (
    <ErrorBoundary>
      {/* Default page title. Sub-apps can override by rendering their own Helmet components */}
      <Helmet
        defaultTitle="Starbucks Coffee Company"
        titleTemplate="%s: Starbucks Coffee Company"
      />

      <ModalProvider targetElementId="modal-target">
        <div
          className={
            routeOptions?.usesFullPageFlexLayout
              ? 'height-100 flex flex-column'
              : ''
          }
        >
          {/* ShellSkipLink has a z-index of 10 set by the Pattern Library component. */}
          {mainContentAllowFocus && <ShellSkipLink />}

          {hasGlobalNav && (
            <GlobalNav
              hamburgerNavIsOpen={hamburgerNavIsOpen}
              nav={nav}
              preventFocusTargets={preventFocusTargets}
              routeOptions={routeOptions}
            />
          )}

          <MainContent
            authSatisfied={authSatisfied}
            hasGlobalNav={hasGlobalNav}
            nav={nav}
            preventFocusTargets={preventFocusTargets}
            routeOptions={routeOptions}
          />
          {/*
           * Global layers get attached to a div that is z-indexed above the
           * main React app, so they always appear "above" everything in this file.
           */}
          <GlobalLayers routeOptions={routeOptions} />
        </div>
      </ModalProvider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  // nav comes from the app/subapp definition
  nav: PropTypes.shape({
    globalNav: PropTypes.shape({
      component: PropTypes.elementType,
      props: PropTypes.object,
    }),
    footerNav: PropTypes.shape({
      component: PropTypes.elementType,
      props: PropTypes.object,
    }),
  }),
  // routes also comes from the app or subapp definition,
  // but is not used as a prop in the component itself.
  // Instead it is used as `ownProps` in the mapStateToProps
  // function (ie. what we call 'select()' here) to retrieve
  // the custom options passed to the current route.
  routes: PropTypes.object,
};

// select function exported for testing
export const select = (state, ownProps) => {
  // reminder that ownProps is part of the base react-redux boilerplate
  // https://react-redux.js.org/using-react-redux/connect-mapstate#arguments
  const route = routeSelector(state);
  const routeOptions = ownProps.routes[route];
  const signedIn = signedInSelector(state);

  return {
    authSatisfied: routeOptions?.authRequired && !signedIn ? false : true,
    hamburgerNavIsOpen: hamburgerNavIsOpenSelector(state),
    hideGlobalNav: routeOptions?.hideGlobalNav || false,
    pathname: pathnameSelector(state),
    preventFocusTargets: preventFocusTargetsSelector(state),
    preventScrolling: preventScrollingSelector(state),
    routeOptions,
    signedIn,
  };
};

const actions = {
  doLogin: doLoginAction,
};

export default connect(select, actions)(App);
