import { parseResponseBody } from './fetch-utils';
import { getFraudRisk } from 'shared/app/utils/iovation';

const ORCHESTRA = 'orchestra';
const WALLET = 'wallet';

export const LOCAL_BFF = '/bff/proxy/';
export const API_PROXY_V1 = '/apiproxy/v1/';
export const destinationTypes = {
  LOCAL_BFF,
  API_PROXY_V1,
};

export const gqlTargets = { ORCHESTRA, WALLET };

const firstCatchStub = (err) => {
  throw err;
};

export const createGQLFetcher = (firstCatch = firstCatchStub, firstThen) => {
  return async (opts) => {
    const {
      operationId,
      target = ORCHESTRA,
      destinationType = destinationTypes.LOCAL_BFF,
      variables = {},
      fetchOpts,
      allowNonFatalErrors,
      includeRisk = false,
      includeAccertify = false,
      setRiskProp = null,
    } = opts;

    const url = `${destinationType}${target}/${operationId}`;
    const bodyParams = {};

    bodyParams.variables = variables;

    if (includeRisk) {
      const risk = await getFraudRisk(includeAccertify);
      if (!setRiskProp) {
        bodyParams.variables.risk = risk;
      } else {
        // caller defined function to set non-standard risk props
        setRiskProp({ variables: bodyParams.variables, risk });
      }
    }

    const combinedFetchOpts = {
      method: 'post',
      headers: {
        accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyParams),
      credentials: 'same-origin',
      ...fetchOpts,
    };

    return fetch(url, combinedFetchOpts)
      .then(parseResponseBody)
      .then(({ response, body }) => {
        const responseOk = response.status >= 200 && response.status < 300;
        if (!responseOk) {
          const error = new Error(response?.statusText);
          error.httpStatus = response.status;
          error.data = body;
          throw error;
        }
        return body;
      })
      .then((parsed) => {
        if (parsed?.errors?.length) {
          // we want to prioritize anything that is an
          // error due to permissions not being allowed.
          parsed.errors.forEach((error) => {
            // we have at least one gql response where error message comes back
            // as an object:
            // https://scm.starbucks.com/starbucks-web/graphql/blob/27e95df7ebd4b43186a9a615527232c70aa9c4f2/src/schema/stored-value-card/resolvers/lost-stolen-svc-resolver.js#L26-L30
            const message =
              typeof error?.message === 'string' && error.message.toLowerCase();
            if (message === 'unauthorized' || message === 'forbidden') {
              // normalize this to one so we don't
              // have to check this same list again
              throw new Error('SessionExpired');
            }
          });
          // otherwise just throw first error
          if (!allowNonFatalErrors) {
            throw parsed.errors[0];
          }
        }
        return parsed.data;
      })
      .catch(firstCatch)
      .then((result) => {
        if (firstThen) {
          return firstThen(result);
        }
        return result;
      });
  };
};

export default createGQLFetcher;
