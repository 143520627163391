export const setSessionStorageItem = (key, value) => {
  try {
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    return null;
  }
};

export const getSessionStorageItem = (key) => {
  try {
    const jsonValue = sessionStorage.getItem(key);
    return jsonValue ? JSON.parse(jsonValue) : {};
  } catch (e) {
    return {};
  }
};

export const removeSessionStorageItem = (key) =>
  sessionStorage && sessionStorage.removeItem && sessionStorage.removeItem(key);

export const BOTTOM_SHEET_RETURN_URL = 'SbuxBottomSheetReturnUrl';
export const CARD_INPUTS_KEY = 'SbuxCardInputs';
export const GIFT_INPUTS_KEY = 'SbuxGiftInputs';
export const ORDER_TYPE_MODAL_KEY = 'SbuxOrderTypeModal';
