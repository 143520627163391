import { push } from 'redux-first-history';
import { cookie } from 'browser-cookie-lite';
import ms from 'milliseconds';

import {
  SET_HAMBURGER_NAV_OPEN,
  SET_HAMBURGER_NAV_CLOSED,
  CLEAR_CLOSED_MODAL_HASH,
  RELOAD_APP,
  APP_IDLE,
  SET_BOTTOM_SHEET_URL,
} from '../actions';
import {
  urlAndQuerySelector,
  hashSelector,
} from 'shared/app/state/selectors/routes';
import { bottomSheetUrlSelector } from '../selectors/app-ui';
import {
  cacheOptionsSelector,
  languageCookieNameSelector,
} from '../selectors/config';

import { DO_LOGIN, DO_LOGOUT } from 'shared/app/bundles/user/actions.js';
import { disableAutoSignin } from 'shared/app/utils/credential-management';
import redirectToSigninWithReturnUrl from 'shared/app/utils/redirect-to-signin-with-return-url';
import reloadPageFromNetwork from 'shared/app/utils/reload-page-from-network';
import resetApp from 'shared/app/utils/reset-app';
import { clearContentLanguageSpecificCaches } from 'shared/app/utils/cache-helper';

export const appIdle = (now = Date.now()) => {
  return { type: APP_IDLE, payload: now };
};

export const clearClosedModalHash = (hash) => (dispatch) => {
  dispatch({ type: CLEAR_CLOSED_MODAL_HASH, payload: hash });
};

// Clean up the url hash if one of the modalsOpenedByUrlHash was just closed
export const setModalByHashClosed = (hash) => (dispatch, getState) => {
  const state = getState();
  const currentUrlWithoutHash = urlAndQuerySelector(state);
  const currentUrlHash = hashSelector(state);
  // if the currentUrlHash doesn't match, don't do anything.
  if (currentUrlHash === hash) {
    // remove the url hash if we just closed the modal that uses that hash
    dispatch(push(currentUrlWithoutHash));
  }
};

export const setBottomSheetUrl = () => {
  return (dispatch, getState) => {
    const currentUrl = bottomSheetUrlSelector(getState());

    dispatch({
      type: SET_BOTTOM_SHEET_URL,
      payload: currentUrl,
    });
  };
};

export const reloadApp = () => (dispatch) => {
  dispatch({ type: RELOAD_APP });
  window.location.reload();
};

export const doLogin =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: DO_LOGIN });
    redirectToSigninWithReturnUrl({ returnUrl: params.returnUrl });
  };

export const doLogout = () => (dispatch, getState) => {
  dispatch({ type: DO_LOGOUT });

  const cacheOptions = cacheOptionsSelector(getState());

  return resetApp({ cacheOptions }).then(() => {
    redirectToSigninWithReturnUrl();
  });
};

export const doLogoutAndForget =
  (returnUrl = null) =>
  (dispatch, getState) => {
    dispatch({ type: DO_LOGOUT });
    const cacheOptions = cacheOptionsSelector(getState());

    return resetApp({ cacheOptions, explicitSignOut: true })
      .then(() => {
        return disableAutoSignin().catch(() => {
          // Swallowing the error for headless Chrome (Puppeteer) when Credentials API throws DOMException
        });
      })
      .then(() => {
        redirectToSigninWithReturnUrl({ returnUrl });
      });
  };

export const switchLanguage = (language) => (dispatch, getState) => {
  const languageCookieName = languageCookieNameSelector(getState());
  const ttlInSeconds = ms.years(100) / 1000;
  cookie(languageCookieName, language, ttlInSeconds, '/');

  const cacheOptions = cacheOptionsSelector(getState());

  return clearContentLanguageSpecificCaches(cacheOptions).then(() =>
    reloadPageFromNetwork(language)
  );
};

export const setHamburgerNavIsOpen = (isOpen) => (dispatch) => {
  if (isOpen) {
    dispatch({
      type: SET_HAMBURGER_NAV_OPEN,
    });
  } else {
    dispatch({
      type: SET_HAMBURGER_NAV_CLOSED,
    });
  }
};
