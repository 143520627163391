import config from 'config';
import { OptimizelyManager } from 'shared/app/components/optimizely';
import readCookie from './read-cookie';

const { cookieName: optimizelyCookieName, environment: optimizelyenvironment } =
  config.get('universal.optimizely');

export const getOptimizelyFeatureFlag = async (flagName) => {
  const optimizelyUserId = readCookie(optimizelyCookieName);
  const optimizely = OptimizelyManager.getInstance();

  const optimizelyAttributes = {
    environment: optimizelyenvironment,
    'ux_exp_id': optimizelyUserId,
  };

  await optimizely.onReady();

  const isFeatureFlagEnabled = optimizely.isFeatureEnabled(
    flagName,
    optimizelyUserId,
    optimizelyAttributes
  );

  return isFeatureFlagEnabled;
};
