import { defineMessages } from 'react-intl';

export default defineMessages({
  orderPickupCardHeader: {
    id: 'shared.orderPickupCardHeader',
    defaultMessage: 'Order in progress',
    description:
      'Header text for previous orders page when an order is in progress',
  },
  orderPickupCardSubHeader: {
    id: 'shared.orderPickupCardSubHeader',
    defaultMessage: 'Estimated pickup is at {pickupTime}',
    description:
      'Sub-Header text for previous orders page when an order is in progress',
  },
  orderPickupTimeLoading: {
    id: 'shared.orderPickupCard.pickupTimeLoading',
    defaultMessage: 'Getting your order ready time...',
    description: 'Message when calling the order-estimate endpoint',
  },
  orderPickupTimeGeneric: {
    id: 'shared.orderPickupCard.pickupTimeGeneric',
    defaultMessage: 'See you soon, {firstName}!',
    description: 'Generic pickup time for SR costumers',
  },
  orderPickupTimeGenericGuest: {
    id: 'shared.orderPickupCard.pickupTimeGenericGuest',
    defaultMessage: 'See you soon!',
    description: 'Generic pickup time for guest costumers',
  },
  orderPickupTime: {
    id: 'shared.orderPickupCard.orderPickupTime',
    defaultMessage: 'Ready around {orderPickupTime}',
    description: 'Order pickup time message',
  },
});
