const THUMBNAIL_AKAMAI_POLICY = '1by1_tight_288';

export const getThumbnailUriFromMaster = (masterImageUri) =>
  masterImageUri
    ? `${masterImageUri}?impolicy=${THUMBNAIL_AKAMAI_POLICY}`
    : null;

const getThumbnailUri = (product) => {
  if (!product) {
    return null;
  }

  const masterImageUri =
    product?.categoryImageURL ||
    product?.imageURL ||
    product?.forms?.[0]?.imageURL;
  return getThumbnailUriFromMaster(masterImageUri);
};

export default getThumbnailUri;
