import { SET_BOTTOM_SHEET_URL } from '../../shell/state/actions';

/* A url that is relied on for opening up bottom sheets when returning
 * the user from the payments app and wanting to return them directly
 * to a payment related bottom sheet activity.
 */

export default (state = null, action) => {
  switch (action.type) {
    case SET_BOTTOM_SHEET_URL:
      return action.payload;
    default:
      return state;
  }
};
