import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import warningSolid from '@starbucks-web/pattern-library/lib/icons/warning-solid';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Frap from 'shared/app/components/frap-with-messages';

import { signedInSelector } from 'shared/app/bundles/user';
import { isGiftHistoryRouteSelector } from 'shared/app/state/selectors/routes';
import H1 from 'shared/app/components/h1';

import CopyLinkCta from '../copy-link-cta';
import { SUCCESS_PURCHASE_STATUS, PENDING_PURCHASE_STATUS } from '../constants';
import sharedStyles from '../styles.cssm';
import styles from './styles.cssm';
import messages from './messages';
import { trackGiftCardPostOrderCtaClick } from 'shared/app/state/track-event';

const SuccessStatus = ({
  isGiftHistoryRoute,
  numberOfRecipients,
  recipients,
  deliveryMethod,
}) => {
  const recipientEmail = recipients[0].recipientEmail;
  const isClient = deliveryMethod?.toLowerCase() === 'client';

  const copyLink = recipients[0].copyLink;

  const clientHeader = (
    <FormattedMessage
      {...messages.linkSuccessHeader}
      values={{ isGiftHistoryRoute }}
    />
  );
  const clientBody = <FormattedMessage {...messages.linkSuccessBody} />;

  const otherHeader = (
    <FormattedMessage
      {...messages.successHeader}
      values={{ numberOfRecipients }}
    />
  );
  const otherBody = (
    <FormattedMessage
      {...messages.successBody}
      values={{ numberOfRecipients, recipientEmail }}
    />
  );

  return (
    <div className="borderRadius-sm bg-greenLight p3 md-py5 text-center">
      <H1 className="text-semibold pb2" size="lg">
        {isClient ? clientHeader : otherHeader}
      </H1>
      {isClient ? (
        <p
          className={`borderRadius-xs bg-white p2 my3 md-mx9 text-xxs text-center ${sharedStyles.wrapWord}`}
        >
          {copyLink}
        </p>
      ) : null}
      <p className="text-xs md-mx9">{isClient ? clientBody : otherBody}</p>
      {isClient ? (
        <CopyLinkCta
          TagName={Frap}
          className="mt3"
          color="greenAccent"
          copyLink={copyLink}
        />
      ) : null}
    </div>
  );
};

export const PendingStatus = ({
  numberOfRecipients,
  isGiftHistoryRoute,
  deliveryMethod,
  isAuthenticated,
}) => {
  const isEmail = deliveryMethod?.toLowerCase() === 'email';
  const isClient = deliveryMethod?.toLowerCase() === 'client';

  const clientHeader = <FormattedMessage {...messages.linkPendingHeader} />;
  const clientBody = (
    <FormattedMessage
      {...messages.pendingBody}
      values={{
        numberOfRecipients,
        isAuthenticated,
        isGiftHistoryRoute,
        isEmail,
      }}
    />
  );

  const otherHeader = (
    <FormattedMessage
      {...messages.pendingHeader}
      values={{ numberOfRecipients }}
    />
  );
  const otherBody = (
    <FormattedMessage
      {...messages.pendingBody}
      values={{
        numberOfRecipients,
        isAuthenticated,
        isGiftHistoryRoute,
        isEmail,
      }}
    />
  );

  return (
    <div className="borderRadius-sm bg-neutralWarm p3 md-py5 text-center">
      <H1 className="text-semibold" size="lg">
        {isClient ? clientHeader : otherHeader}
      </H1>
      <p className="text-xs mt2 md-mx9">{isClient ? clientBody : otherBody}</p>
    </div>
  );
};

const ErrorStatus = ({ isGiftHistoryRoute }) => {
  const { closeModal } = useModalContext();
  const onClickHandler = () => {
    trackGiftCardPostOrderCtaClick('Try again');
    if (isGiftHistoryRoute) {
      closeModal();
    }
  };

  return (
    <div
      className={`borderRadius-sm p3 md-py5 text-center ${styles.errorStatus}`}
    >
      <Icon className="color-red" path={warningSolid} size="50px" />
      <H1 className="text-semibold py2" size="lg">
        <FormattedMessage {...messages.errorHeader} />
      </H1>
      <p className="text-xs md-mx9">
        <FormattedMessage {...messages.errorBody} />
      </p>
      <Button
        className="mt4 md-mt5"
        color="black"
        onClick={onClickHandler}
        tagName={Link}
        to="/gift"
        visualStyle="positive"
      >
        <FormattedMessage {...messages.tryAgainButton} />
      </Button>
    </div>
  );
};

const StatusBanner = ({
  giftPurchaseDetails: {
    purchaseStatus,
    recipients,
    numberOfRecipients,
    deliveryMethod,
  },
}) => {
  const isGiftHistoryRoute = useSelector(isGiftHistoryRouteSelector);
  const isAuthenticated = useSelector(signedInSelector);
  if (purchaseStatus === SUCCESS_PURCHASE_STATUS) {
    return (
      <SuccessStatus
        deliveryMethod={deliveryMethod}
        isGiftHistoryRoute={isGiftHistoryRoute}
        numberOfRecipients={numberOfRecipients}
        recipients={recipients}
      />
    );
  }
  if (purchaseStatus === PENDING_PURCHASE_STATUS) {
    return (
      <PendingStatus
        deliveryMethod={deliveryMethod}
        isAuthenticated={isAuthenticated}
        isGiftHistoryRoute={isGiftHistoryRoute}
        numberOfRecipients={numberOfRecipients}
      />
    );
  }
  return <ErrorStatus isGiftHistoryRoute={isGiftHistoryRoute} />;
};

export default StatusBanner;
