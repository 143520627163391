import { defineMessages } from 'react-intl';

const messages = defineMessages({
  postalCode: {
    id: 'shared.postalCodeField.label',
    defaultMessage: 'Zip code',
    description: 'Label for a postal code field',
  },
  postalCodeError: {
    id: 'shared.postalCodeField.missingError',
    defaultMessage: 'Invalid ZIP code',
    description: 'Error message displayed when postal code is missing',
  },
});

export default messages;
