import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { push } from 'redux-first-history';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import SharedBottomSheet from 'shared/app/components/bottom-sheet-with-messages';
import { redeemMessages } from '../../messages';

export const REDEEM_SHEET_TITLE_ID = 'REDEEM_SHEET_TITLE_ID';

const RedeemBottomSheet = () => {
  const dispatch = useDispatch();
  const { closeModal } = useModalContext();
  return (
    <SharedBottomSheet
      headingChildren={
        <FormattedMessage {...redeemMessages.redeemOrderModePrompt} />
      }
      headingProps={{
        id: REDEEM_SHEET_TITLE_ID,
      }}
    >
      <button
        className="block mt4 text-left"
        data-e2e="redeemPayInStoreOption"
        onClick={() => {
          closeModal();
          dispatch(push('#pay'));
        }}
      >
        <span className="text-md text-semibold">
          <FormattedMessage {...redeemMessages.redeemOrderInPersonPrompt} />
        </span>
        <p className="pt2 text-sm">
          <FormattedMessage {...redeemMessages.redeemOrderInPersonDetail} />
        </p>
      </button>
      <div className="mt4">
        <Link
          className={'block text-noUnderline'}
          data-e2e="redeemOrderAheadOption"
          onClick={() => {
            closeModal();
          }}
          to={`/menu`}
        >
          <span className="text-md text-semibold">
            <FormattedMessage {...redeemMessages.redeemOrderAheadPrompt} />
          </span>
          <p className="pt2 text-sm">
            <FormattedMessage {...redeemMessages.redeemOrderAheadDetail} />
          </p>
        </Link>
      </div>
    </SharedBottomSheet>
  );
};

export default RedeemBottomSheet;
