import React from 'react';
import FieldStatus from '@starbucks-web/pattern-library/lib/components/field-status';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const renderCriterionPrefix = (hasError) => {
  return (
    <span className="hiddenVisually">
      {hasError === false ? (
        <FormattedMessage {...messages.passwordCriterionValid} />
      ) : (
        <FormattedMessage {...messages.passwordCriterionInvalid} />
      )}
    </span>
  );
};

const PasswordFieldStatusMessages = ({ field = {} }) => {
  const { error: fieldError, errorMessage, errorDetails = {} } = field;
  const fieldHasErrorMessage = fieldError && errorMessage;
  return (
    <ul>
      {fieldHasErrorMessage ? (
        <FieldStatus error>
          {renderCriterionPrefix(true)}
          {<FormattedMessage {...field.errorMessage} />}
        </FieldStatus>
      ) : null}
      <li aria-atomic="true" aria-live="polite">
        <FieldStatus error={errorDetails.stringLength}>
          {renderCriterionPrefix(errorDetails.stringLength)}
          <FormattedMessage {...messages.passwordHintCharacterLength} />
        </FieldStatus>
      </li>
      <li aria-atomic="true" aria-live="polite">
        <FieldStatus error={errorDetails.number}>
          {renderCriterionPrefix(errorDetails.number)}
          <FormattedMessage {...messages.passwordHintNumber} />
        </FieldStatus>
      </li>
      <li aria-atomic="true" aria-live="polite">
        <FieldStatus error={errorDetails.uppercase}>
          {renderCriterionPrefix(errorDetails.uppercase)}
          <FormattedMessage {...messages.passwordHintCapital} />
        </FieldStatus>
      </li>
      <li aria-atomic="true" aria-live="polite">
        <FieldStatus error={errorDetails.lowercase}>
          {renderCriterionPrefix(errorDetails.lowercase)}
          <FormattedMessage {...messages.passwordHintLowercase} />
        </FieldStatus>
      </li>
      <li aria-atomic="true" aria-live="polite">
        <FieldStatus error={errorDetails.special}>
          {renderCriterionPrefix(errorDetails.special)}
          <FormattedMessage {...messages.passwordHintSpecialCharacter} />
        </FieldStatus>
      </li>
    </ul>
  );
};

export default PasswordFieldStatusMessages;
