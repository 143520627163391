import React, { forwardRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Frap from 'shared/app/components/frap-with-messages';
import FrapContainer from 'shared/app/components/frap-container';
import Radio from '@starbucks-web/pattern-library/lib/components/radio';
import RadioGroup from '@starbucks-web/pattern-library/lib/components/radio-group';

import SharedForm from 'shared/app/components/shared-form';

import messages from '../shared-messages';

const MfaChallengeOtherOptionsOverlayForm = forwardRef(
  ({ fields: { radioGroup }, onSubmit, isSendingCode, disableFrap }, ref) => {
    const { formatMessage } = useIntl();
    const [radioValue, setRadioValue] = useState(radioGroup.input.value);

    return (
      <SharedForm onSubmit={onSubmit} ref={ref}>
        <RadioGroup
          {...radioGroup.input}
          legend={formatMessage(messages.sendCodeOptionsRadioGroupLegend)}
          onChange={(event) => {
            radioGroup.input.value = event.target.value;
            setRadioValue(event.target.value);
          }}
          value={radioValue}
        >
          <Radio value="phoneSMS">
            <FormattedMessage {...messages.sendCodeOptionsTextRadioLabel} />
          </Radio>

          <Radio value="phoneVoice">
            <FormattedMessage {...messages.sendCodeOptionsCallRadioLabel} />
          </Radio>
        </RadioGroup>

        <FrapContainer className="md-mt6" relativeAbove="md">
          <Frap
            data-e2e="submitCode"
            disabled={disableFrap}
            loading={isSendingCode}
            type="submit"
          >
            <FormattedMessage {...messages.sendCodeButton} />
          </Frap>
        </FrapContainer>
      </SharedForm>
    );
  }
);

export default MfaChallengeOtherOptionsOverlayForm;
