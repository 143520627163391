import {
  setUserAttribute,
  trackEvent,
  trackPageView,
} from 'shared/app/utils/uo-tracking';

export const UO_GUEST_SIGN_IN = 'sign in';
export const UO_GUEST_SIGN_UP = 'sign up';

/**
 * @param {{click_target: string, cta_placement: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGuestSrCtaClick = (eventAttributes) =>
  trackEvent('GUEST_SR_CTA_CLICK', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackCheckoutAsGuestClick = () => {
  trackEvent('MOP_GUEST_CHECKOUT_CLICK');
  setUserAttribute('customer_type', 'guest');
};

/**
 * @param {string} ctaName - Possible values: "Browse eGfitCards", "Check again", "Join now" or "Try again"
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftCardPostOrderCtaClick = (ctaName) => {
  return trackEvent('GIFT_CARD_POST_ORDER_CTA_CLICK', {
    'click_target': ctaName,
  });
};

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMopPostOrderView = () => {
  return trackPageView('MOP_POST_ORDER_VIEW');
};

/**
 * @param {{orderPickupWaitTimeEstimateInSeconds: string, orderId: string, isPreOrderFallback: boolean}}  - eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMopPostOrderWaitTimeView = ({
  orderPickupWaitTimeEstimateInSeconds,
  orderId,
  isPreOrderFallback,
}) => {
  return trackEvent('MOP_POST_ORDER_WAIT_TIME_VIEW', {
    'wait_time_estimate': orderPickupWaitTimeEstimateInSeconds,
    'order_id': orderId,
    ...(isPreOrderFallback
      ? { 'is_pre_order_fallback': isPreOrderFallback }
      : {}),
  });
};

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMopPostOrderDetailsClick = () => {
  return trackEvent('MOP_POST_ORDER_DETAILS_CLICK');
};
