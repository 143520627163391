import React from 'react';

import Button from '@starbucks-web/pattern-library/lib/components/button';

export const FullWidthButton = ({
  icon,
  className,
  children,
  ...otherProps
}) => {
  return (
    <div className="mx3">
      <Button className={`width-100 py3 ${className}`} {...otherProps}>
        {icon ? <span className="mr2">{icon}</span> : null}
        {children}
      </Button>
    </div>
  );
};
