import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import Form from '@starbucks-web/pattern-library/lib/components/form';

import messages from './messages';

const FormWithMessaging = forwardRef((props, ref) => {
  const { formatMessage } = useIntl();
  return (
    <Form
      {...props}
      ref={ref}
      requiredIndicatorText={formatMessage(messages.requiredFieldIndicator)}
    />
  );
});

export default FormWithMessaging;
