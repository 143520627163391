import BadWordsFilter from 'bad-words';
import { validateExistence } from '@starbucks-web/pattern-library/lib/components/form-container/validators';

import { emailRegex } from 'shared/universal/email-regex';

const checkDetailsForErrors = (errorDetails) =>
  Object.keys(errorDetails).some((key) => errorDetails[key]);

export const validateEmail = ({ value }) => {
  const errorDetails = {
    existence: validateExistence({ value }).error,
    email: !emailRegex.test(value),
  };

  return {
    error: checkDetailsForErrors(errorDetails),
    errorDetails,
  };
};

export const getValidateNumberRange =
  ({ min, max }) =>
  ({ value }) => {
    const errorDetails = {
      existence: validateExistence({ value: value.toString() }).error,
      range: isNaN(value) || value < min || value > max,
    };

    return {
      error: checkDetailsForErrors(errorDetails),
      errorDetails,
    };
  };

export const getValidateLength =
  (options) =>
  ({ value }) => {
    const opts = Object.assign(
      {},
      {
        min: 0,
        max: 100,
        validateExistence: true,
      },
      options
    );

    const errorDetails = {
      range: value.length < opts.min || value.length > opts.max,
    };

    if (opts.validateExistence) {
      errorDetails.existence = validateExistence({ value }).error;
    }

    return {
      error: checkDetailsForErrors(errorDetails),
      errorDetails,
    };
  };

export const isNumericWithExactLength = (str, length) => {
  const regex = new RegExp(`^[0-9]{${length}}$`);
  return regex.test(str);
};

export const isAlphanumericWithExactLength = (str, length) => {
  const regex = new RegExp(`^[a-zA-Z0-9_]{${length}}$`);
  return regex.test(str);
};

export const getValidateNumericWithExactLength =
  (len) =>
  ({ value }) => {
    const errorDetails = {
      existence: validateExistence({ value: value.toString() }).error,
      numeric: !isNumericWithExactLength(value, len),
    };

    return {
      error: checkDetailsForErrors(errorDetails),
      errorDetails,
    };
  };

export const getValidateAlphanumericWithExactLength =
  (len) =>
  ({ value }) => {
    const errorDetails = {
      existence: validateExistence({ value: value.toString() }).error,
      alphanumeric: !isAlphanumericWithExactLength(value, len),
    };

    return {
      error: checkDetailsForErrors(errorDetails),
      errorDetails,
    };
  };

const badWordsFilter = new BadWordsFilter();
const nameSpecialCharactersRegex =
  /[\u00EB\u00CB\u00D8\u00D8\u00F8\@\$\^\&\*\#\!]/;

export const validateName = (
  { value },
  fieldsState,
  skipProfanityCheck = false
) => {
  const isProfane = !skipProfanityCheck && badWordsFilter.isProfane(value);
  const isInvalid = nameSpecialCharactersRegex.test(value);
  const errorDetails = {
    existence: validateExistence({ value }).error,
    characters: isInvalid,
    profanity: isProfane,
  };

  return {
    error: Object.keys(errorDetails).some((key) => errorDetails[key]),
    errorDetails,
  };
};

export const validatePhone =
  (length) =>
  ({ value }) => {
    const isValid = value && isNumericWithExactLength(value, length);
    return {
      error: !isValid,
      errorDetails: {
        phone: isValid,
      },
    };
  };

export const validatePostalCode = ({ value }, currentLocaleTag) => {
  let regex;
  switch (currentLocaleTag) {
    case 'en-CA':
    case 'fr-CA':
      // Regular expression for Canada postal codes (A1A 1A1)
      regex = /^[A-Z]\d[A-Z]\s\d[A-Z]\d$/;
      break;

    default:
      // Regular expression for US ZIP codes (5 numeric digits)
      regex = /^\d{5}?$/;
      break;
  }

  const isValid = regex.test(value);

  return {
    error: !isValid,
    errorDetails: {
      postalCode: isValid,
    },
  };
};
