import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import IS_BROWSER from '../../utils/is-browser';

import Button from '@starbucks-web/pattern-library/lib/components/button';

import { signedInSelector } from 'shared/app/bundles/user';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

// path is relative so that app.starbucks.com/account/create is used within the app,
// and www.starbucks.com/account/create is used within dotcom.
const ACCT_CREATE_PATH = '/account/create';

const JoinRewardsLink = ({
  className,
  targetUrl = IS_BROWSER ? window.location.href : '',
  onClick,
  color,
}) => {
  const { formatMessage } = useIntl();
  const signedIn = useSelector(signedInSelector);

  if (signedIn) {
    return null;
  }

  const encodedTargetUrl = encodeURIComponent(targetUrl);

  return (
    <Button
      className={className}
      color={color}
      data-e2e="joinRewardsLink"
      href={`${ACCT_CREATE_PATH}?returnUrl=${encodedTargetUrl}`}
      onClick={onClick}
      visualStyle="positive"
    >
      {formatMessage(sharedCallsToAction.joinNow)}
    </Button>
  );
};

JoinRewardsLink.propTypes = {
  className: PropTypes.string,
  targetUrl: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['gold', 'black', 'white', 'houseGreen', 'red']),
};

export default JoinRewardsLink;
