// This file serves as a global index of all available actions that
// can occur within the app.
//
// Categories below match files in `./action-creators`

// application lifecycle
export const RELOAD_APP = 'RELOAD_APP';
export const APP_IDLE = 'APP_IDLE';

// global nav
export const SET_HAMBURGER_NAV_OPEN = 'SET_HAMBURGER_NAV_OPEN';
export const SET_HAMBURGER_NAV_CLOSED = 'SET_HAMBURGER_NAV_CLOSED';

// notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

// bottom sheets
export const SET_BOTTOM_SHEET_URL = 'SET_BOTTOM_SHEET_URL';

// modals
export const CLEAR_CLOSED_MODAL_HASH = 'CLEAR_CLOSED_MODAL_HASH';

// Mfa token
export const VERIFY_MFA_ACCESS = 'VERIFY_MFA_ACCESS';
export const VERIFY_MFA_ACCESS_ERROR = 'VERIFY_MFA_ACCESS_ERROR';
export const VERIFY_MFA_ACCESS_SUCCESS = 'VERIFY_MFA_ACCESS_SUCCESS';

// // ▼ capabilities ▼

// geolocation
export const GET_GEOLOCATION = 'GET_GEOLOCATION';
export const GET_GEOLOCATION_SUCCESS = 'GET_GEOLOCATION_SUCCESS';
export const GET_GEOLOCATION_ERROR = 'GET_GEOLOCATION_ERROR';

// // ▲ capabilities ▲
