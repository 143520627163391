import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import directionsPath from '@starbucks-web/pattern-library/lib/icons/directions';

import { hasGeolocationSelector } from 'shared/app/bundles/geolocation';
import ExternalLink from 'shared/app/components/external-link';
import buildDirectionsUrl from 'shared/app/utils/build-directions-url';

import messages from './messages';
import ariaMessages from '../external-link/messages';

const DirectionsLink = ({ storeAddressLines, visualType = 'Button' }) => {
  const { formatMessage } = useIntl();
  const hasGeolocation = useSelector(hasGeolocationSelector);

  if (!storeAddressLines.length) {
    return null;
  }

  const fullAddress = storeAddressLines.join(', ');
  const url = buildDirectionsUrl(fullAddress, hasGeolocation);

  if (visualType === 'Button') {
    return (
      <ExternalLink
        className="color-greenAccent"
        href={url}
        visualStyle="button"
      >
        {formatMessage(messages.directionsLink)}
      </ExternalLink>
    );
  }

  return (
    <a
      aria-label={`${formatMessage(messages.directionsLink)}, ${formatMessage(ariaMessages.externalLinkAria)}`}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon className="color-greenAccent" path={directionsPath} size={24} />
    </a>
  );
};

export default DirectionsLink;
