import { LOCATION_CHANGE } from 'redux-first-history';
import { CLEAR_CLOSED_MODAL_HASH } from 'shared/app/shell/state/actions';

export default (modalsByUrlHash = []) => {
  const defaultState = { modalsOpenedByUrlHash: modalsByUrlHash };

  return (state = defaultState, action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        const urlHashValue = action.payload.location.hash;
        // if the current url hash matches one of our registered modals,
        // mark it active to be picked up by a selector.
        const { modalsOpenedByUrlHash } = state;
        const modalToOpen = modalsOpenedByUrlHash.find(
          (modalHash) => urlHashValue === modalHash.hash
        );
        if (urlHashValue) {
          let newState = { ...state };
          if (state.active && state.active.hash !== urlHashValue) {
            // need to close the active modal while another is possibly opening
            newState = {
              ...newState,
              active: null,
              toBeClosed: { ...state.active },
            };
          }
          if (modalToOpen) {
            newState = { ...newState, active: modalToOpen };
          }
          return newState;
        }

        // if the hash was just removed and a modal is open, mark the modal
        // to be closed (user may have hit back button or we performed
        // a navigation outside the control of the open modal)
        if (state.active) {
          return { ...state, active: null, toBeClosed: { ...state.active } };
        }
        return { ...state, active: null, toBeClosed: null };

      case CLEAR_CLOSED_MODAL_HASH:
        // This is a cleanup task after a modal is closed
        const closedHash = action.payload;
        // make sure the requested hash to clear is what is currently
        // in the toBeClosed state...
        if (state.toBeClosed.hash === closedHash) {
          return { ...state, active: null, toBeClosed: null };
        }
        // ... else just return the current state
        return state;

      default:
        return state;
    }
  };
};
