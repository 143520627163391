import React, { forwardRef } from 'react';

const SharedForm = forwardRef(
  ({ method = 'post', noValidate = true, ...otherProps }, ref) => {
    return (
      <form {...otherProps} method={method} noValidate={noValidate} ref={ref} />
    );
  }
);

export default SharedForm;
