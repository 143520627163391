import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import { sharedCallsToAction } from '../../messages/ctas';
import RedeemBottomSheet, { REDEEM_SHEET_TITLE_ID } from './redeem-bottomsheet';
import RedeemOverlay, { redeemOverlayHeadingId } from './redeem-overlay';

export const RedeemOptionsButton = ({
  className,
  clickHandler,
  isMobile,
  showOrderLabel = false,
}) => {
  const { openModal } = useModalContext();

  const showRedemptionOptions = () => {
    if (isMobile) {
      openModal({
        component: RedeemBottomSheet,
        ariaLabelledBy: REDEEM_SHEET_TITLE_ID,
      });
    } else {
      openModal({
        component: RedeemOverlay,
        ariaLabelledBy: redeemOverlayHeadingId,
      });
    }
  };

  const buttonMessage = showOrderLabel
    ? sharedCallsToAction.order
    : sharedCallsToAction.redeem;

  return (
    <Button
      className={className}
      data-e2e="redeemStars"
      onClick={() => {
        clickHandler && clickHandler();
        showRedemptionOptions();
      }}
      visualStyle="positive"
    >
      <FormattedMessage {...buttonMessage} />
    </Button>
  );
};

RedeemOptionsButton.propTypes = {
  className: PropTypes.string,
  clickHandler: PropTypes.func,
  showOrderLabel: PropTypes.bool,
};

const select = (state) => ({
  isMobile: isMobileViewportSelector(state),
});

export default connect(select)(RedeemOptionsButton);
