import { createSelector } from 'reselect';

import { isMobileViewportSelector } from '../../../bundles/viewport';
import {
  currentRouteSelector,
  hashSelector,
} from 'shared/app/state/selectors/routes';

export const appTimeSelector = (state) => state.time;
export const bottomSheetReturnUrlSelector = (state) =>
  state.bottomSheetReturnUrl;
export const hamburgerNavSelector = (state) => state.hamburgerNav;
export const maskActiveSelector = (state) => state.mask.active;
export const maskClickHandlerSelector = (state) => state.mask.maskClickHandler;
export const modalsStateSelector = (state) => state.modals;

export const routerHashSelector = createSelector(hashSelector, (hash) =>
  hash ? hash.substring(1) : undefined
);

export const modalToOpenByHashSelector = createSelector(
  modalsStateSelector,
  (modalState) => modalState.active
);

export const modalToBeClosedSelector = createSelector(
  modalsStateSelector,
  (modalState) => modalState.toBeClosed
);

export const bottomSheetUrlSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => `${currentRoute.pathname}${currentRoute.hash}`
);

export const hamburgerNavIsOpenSelector = createSelector(
  hamburgerNavSelector,
  (hamburgerNav) => hamburgerNav.isOpen
);

/* eslint-disable max-params, complexity */
export const preventScrollingSelector = createSelector(
  hamburgerNavIsOpenSelector,
  (hamburgerNavIsOpen) => {
    return hamburgerNavIsOpen;
  }
);

/* preventFocusTargetsSelector is not really doing anything any longer,
 * (all modals handle focus management internally now)
 * but leaving it here to remove in a separate ticket since it has lots
 * of usages to clean up. /browse/WG-13593
 */
export const preventFocusTargetsSelector = createSelector(
  hamburgerNavIsOpenSelector,
  isMobileViewportSelector,
  (mobileNavOpen, isMobileViewport) => {
    // In mobile viewports, mobile nav covers the UI.
    const mobilePreventFocusToAll = mobileNavOpen;

    const preventFocusToAll = isMobileViewport && mobilePreventFocusToAll;

    const preventFocusToContent = preventFocusToAll;

    const preventFocusToHeader = preventFocusToAll;

    return {
      all: preventFocusToAll,
      header: preventFocusToHeader,
      content: preventFocusToContent,
    };
  }
);
