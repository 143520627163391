import { defineMessages } from 'react-intl';

export default defineMessages({
  orderNumber: {
    id: 'shared.giftReceipt.orderNumber',
    defaultMessage:
      '{orderNumber, select, false {Order number unavailable} other {Order number #{orderNumber}}}',
    description: 'Order number info text',
  },
  copyLinkCta: {
    id: 'shared.giftReceipt.copyLinkCta',
    description: 'Message for cta to copy eGift link',
    defaultMessage: 'Copy {isEmail, select, true {} other {eGift}} link',
  },
});
