import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import CollectingStars from './collecting-stars-animation';

import { starBalanceSelector } from 'shared/app/bundles/user';

import { isTrackerCompletedSelector } from 'shared/app/state/selectors/rewards-tracker';
import AccessibleStar from '../accessible-star';

const StarBalance = ({
  isTrackerCompleted,
  stars,
  appliedStarsCart,
  className,
}) => {
  const starsCount = stars - appliedStarsCart;
  return (
    <div className={`${className} flex items-center justify-center`}>
      <div className="hiddenVisually">
        <FormattedMessage
          defaultMessage={
            'You have {starCount} Reward {starCount, plural, one {star} other {stars}}'
          }
          description="Number of reward stars currently in user account"
          id="shared.starBalance.starCount"
          values={{
            starCount: starsCount,
          }}
        />
      </div>
      <div aria-hidden data-e2e="starCount">
        {starsCount}
      </div>
      <div className="relative">
        <AccessibleStar starCount={starsCount} />
        {isTrackerCompleted && <CollectingStars />}
      </div>
    </div>
  );
};

StarBalance.displayName = 'StarBalance';

StarBalance.propTypes = {
  className: PropTypes.string,
  stars: PropTypes.number,
  appliedStarsCart: PropTypes.number,
};

StarBalance.defaultProps = {
  stars: 0,
  appliedStarsCart: 0,
};

const select = (state) => ({
  stars: starBalanceSelector(state),
  isTrackerCompleted: isTrackerCompletedSelector(state),
});

export default connect(select)(injectIntl(StarBalance));
