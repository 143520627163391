import hamburgerNav from './hamburger-nav';
import notifications from './notifications';
import time from './time';
import termsPrivacy from 'shared/app/state/reducers/terms-privacy';
import bottomSheetReturnUrl from 'shared/app/state/reducers/bottom-sheet-return-url';

export default {
  bottomSheetReturnUrl,
  hamburgerNav,
  notifications,
  time,
  termsPrivacy,
};
