import { defineMessages } from 'react-intl';

const messages = defineMessages({
  directionsLink: {
    id: 'expandedLocationCardDetails.directionsLink',
    defaultMessage: 'Get Directions',
    description: 'Link text for getting directions to store',
  },
});

export default messages;
