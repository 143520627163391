const canadaPostalCodeFormatter = (postalCodeValue) => {
  const formattedPostalCode = postalCodeValue
    .replaceAll(/\W/gi, '')
    .toUpperCase();

  if (formattedPostalCode.length > 3) {
    return `${formattedPostalCode.slice(0, 3)} ${formattedPostalCode.slice(3)}`;
  }

  return formattedPostalCode;
};

const usPostalCodeFormatter = (postalCodeValue) => {
  const formattedPostalCode = postalCodeValue.replaceAll(/\D/g, '');
  return formattedPostalCode;
};

const postalCodeFormatterMap = {
  'en-CA': canadaPostalCodeFormatter,
  'en-US': usPostalCodeFormatter,
  'fr-CA': canadaPostalCodeFormatter,
};

export const postalCodeFormatter = (locale) => (postalCodeValue) => {
  return postalCodeFormatterMap[locale]?.(postalCodeValue) ?? postalCodeValue;
};
