'use strict';

exports.FEATURES = {
  EMERGENCY_MOP_OUTAGE: 'emergency_mop_outage',
  SCHEDULED_ORDERING: 'bts_scheduled_ordering',
  DEMYSTIFY_MENU: 'demystify_menu',
  DEMYSTIFY_MENU_PDP: 'demystify_menu_pdp',
  DEMYSTIFY_MENU_PREVIOUS: 'demystify_menu_previous',
};

exports.EXPERIMENTS = {};
