import { PseudoHttpError } from 'shared/universal/custom-errors';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import { localeTagSelector } from 'shared/app/state/selectors/locales';

import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
} from './action-types';
import { getUrlWithQuery, getStoreNumberQuery } from './util/get-filter-query';

export const baseMenuPath = '/apiproxy/v1/ordering/menu';

export const fetchMenu =
  () =>
  (dispatch, getState, { apiFetch }) => {
    const state = getState();
    const storeNumber =
      selectedStoreSelector(state)?.store?.storeNumber ?? null;
    const locale = localeTagSelector(state);
    const storeNumberQuery = getStoreNumberQuery(storeNumber);

    const options = {
      headers: {
        'Accept-Language': locale,
      },
    };

    dispatch({
      type: FETCH_MENU,
      payload: {
        storeNumber,
      },
    });

    const url = getUrlWithQuery(baseMenuPath, storeNumberQuery);

    return apiFetch(url, options)
      .then((data) => {
        const payload = data?.menus;

        dispatch({
          type: FETCH_MENU_SUCCESS,
          payload,
        });
      })
      .catch((err) => {
        // If we get something directly from fetch, we likely have deeper connection problems,
        // so we default to 503 Service Unavailable
        const status = err.status || 503;
        const httpError = new PseudoHttpError({
          code: status,
          message: `Got ${status} from ${url}`,
        });
        dispatch({
          type: FETCH_MENU_ERROR,
          error: httpError,
        });
      });
  };
