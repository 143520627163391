const REQUIRED = ['name'];
const OPTIONAL = [
  'asyncActions',
  'effects',
  'initialize',
  'persistAfter',
  'reducer',
  'routes',
  'modalsOpenedByUrlHash',
  'mergeCachedAndBootstrapData',
  // convenience properties
  'components',
  'selectors',
];
const SYNC_ONLY = ['mergeCachedAndBootstrapData'];
const ASYNC_REQUIRED = ['reducer'];

// validator function to ensure apps have
// keys named correctly. This is to catch
// otherwise silent errors
export const validateSubApp = (app) => {
  REQUIRED.forEach((key) => {
    if (!app[key]) {
      throw Error(`Sub apps must have "${key}"`);
    }
  });

  Object.keys(app).forEach((key) => {
    if (OPTIONAL.indexOf(key) === -1 && REQUIRED.indexOf(key) === -1) {
      throw Error(`Sub apps cannot have "${key}"`);
    }
  });
};

export const validateAsyncSubApp = (app) => {
  validateSubApp(app);
  ASYNC_REQUIRED.forEach((key) => {
    if (!app[key]) {
      throw Error(`Async apps must have "${key}"`);
    }
  });
  SYNC_ONLY.forEach((key) => {
    if (app[key]) {
      throw Error(`Async apps can't have "${key}"`);
    }
  });
};
