import React from 'react';
import { useIntl } from 'react-intl';
import PLButton from '@starbucks-web/pattern-library/lib/components/button';
import { commonMessages } from 'shared/app/messages/common-words';

const Button = ({ ...otherProps }) => {
  const { formatMessage } = useIntl();
  const loadingAriaLiveAnnouncement = formatMessage(
    commonMessages.loadingAnnouncement
  );

  return (
    <PLButton
      loadingAriaLiveAnnouncement={loadingAriaLiveAnnouncement}
      {...otherProps}
    />
  );
};

export default Button;
