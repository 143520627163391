import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Select from '@starbucks-web/pattern-library/lib/components/select';

import { signedInSelector } from 'shared/app/bundles/user/state/selectors';
import { ADD_PAYMENT } from 'shared/app/bundles/wallet';

import styles from './index.cssm';
import { paymentMessages } from 'shared/app/messages';

// source for map keys: PaymentInstrument.paymentType
// https://docs.starbucks.com/display/OAD/StarPayPaymentInstrument
// VISA, Discover, Mastercard, AMEX, PayPal, Bakkt
const paymentMap = {
  'add-payment': paymentMessages.addPayment,
  amex: 'Amex',
  discover: 'Discover',
  mastercard: 'MasterCard',
  visa: 'Visa',
  paypal: 'PayPal',
  venmo: 'Venmo',
};

const PAYPAL = 'paypal';

export const PaymentInstrumentSelect = ({
  paymentInstruments,
  value,
  ...otherProps
}) => {
  const { formatMessage } = useIntl();
  const signedIn = useSelector(signedInSelector);

  const getCurrentCard = () => {
    return paymentInstruments.find((pi) => pi?.paymentInstrumentId === value);
  };

  const getCardData = () => {
    const currentCard = getCurrentCard();
    const paymentType = currentCard?.paymentType;
    const accountNumberLastFour = currentCard?.accountNumberLastFour;
    if (!currentCard || paymentType === ADD_PAYMENT) {
      return {
        cardClassName: styles.defaultCC,
        cardLabel: formatMessage(paymentMessages.selectPaymentInstrument),
      };
    }
    const cardNameSlug = paymentType.toLowerCase();

    return {
      cardClassName: styles[cardNameSlug] || styles.defaultCC,
      cardLabel: formatMessage(paymentMessages.payment),
      cardTypeLabel: paymentType,
      lastFourDigits: accountNumberLastFour,
    };
  };

  const renderSelectOption = (paymentInstrument) => {
    const { accountNumberLastFour, paymentInstrumentId, paymentType } =
      paymentInstrument;
    const normalizedPaymentType = paymentType.toLowerCase();

    let optionText = accountNumberLastFour
      ? `x${accountNumberLastFour}`
      : `${paymentMap[normalizedPaymentType]}`;

    if (paymentType === ADD_PAYMENT) {
      optionText = formatMessage(paymentMap[normalizedPaymentType]);
    }

    if (paymentType === PAYPAL) {
      optionText = paymentMap[normalizedPaymentType];
    }

    return (
      <option key={paymentInstrumentId} value={paymentInstrumentId}>
        {optionText}
      </option>
    );
  };

  const filterPayment = (option) => {
    const paymentOption = option.paymentType.toLowerCase();

    if (paymentOption === PAYPAL && !signedIn) {
      return false;
    }
    return true;
  };

  const { cardClassName, cardLabel, cardTypeLabel, lastFourDigits } =
    getCardData();

  const reloadSourceLabel = formatMessage(
    paymentMessages.reloadSourceA11yLabel
  );
  const selectAriaLabel = `${reloadSourceLabel}: ${cardTypeLabel}, ${cardLabel}`;

  const paymentOptions = paymentInstruments
    .filter(filterPayment)
    .map(renderSelectOption);

  return (
    <div className="flex">
      <span className={`mr3 ${cardClassName}`} />
      <div className="flex-grow">
        <Select
          aria-label={lastFourDigits ? selectAriaLabel : reloadSourceLabel}
          data-e2e="payment-instruments"
          label={cardLabel}
          value={value}
          {...otherProps}
        >
          {paymentOptions}
        </Select>
      </div>
    </div>
  );
};

PaymentInstrumentSelect.propTypes = {
  id: PropTypes.string.isRequired,
};

PaymentInstrumentSelect.defaultProps = {
  id: 'paymentInstrument',
};

export default PaymentInstrumentSelect;
