import { useEffect, useState } from 'react';
import config from 'config';
import { useDispatch } from 'react-redux';
import isApplePaySupported from 'shared/app/utils/is-apple-pay-supported';
import { CLEAR_CONTINUE_AS_GUEST } from 'shared/app/state/action-creators/types';

const creditDebitGuestCheckoutEnabled = config.get(
  'universal.creditDebitGuestCheckoutEnabled'
);

const useCreditDebitGuestCheckoutEnabledFlag = () => {
  const dispatch = useDispatch();

  const [
    isCreditDebitGuestCheckoutEnabled,
    setIsCreditDebitGuestCheckoutEnabled,
  ] = useState(creditDebitGuestCheckoutEnabled);

  // This handles an edge case for unsupported Apple Pay browsers.
  // The scenario is when a user adds items to the cart, continues as a guest,
  // never completes the order, and the GCO CC/DC flag is turned off.
  // This allows the user to continue with the option to sign in or create an account from the cart.
  useEffect(() => {
    if (!isApplePaySupported && !creditDebitGuestCheckoutEnabled) {
      setIsCreditDebitGuestCheckoutEnabled(false);
      dispatch({ type: CLEAR_CONTINUE_AS_GUEST });
    }
  }, []);

  return { isCreditDebitGuestCheckoutEnabled };
};

export default useCreditDebitGuestCheckoutEnabledFlag;
