import config from 'config';
import loadScript from 'shared/app/utils/load-script';

export const assetsUrl = config.get('universal.staticUrls.assets.path', '');
export const IOVATION_GLOBAL_NAME = 'IGLOO';
export const IOVATION_MAX_DELAY = 5000;

export let bbValue = '';
export let bbComplete = false;

const handleBlackBox = (bb, complete) => {
  // According to the Iovation Team, even a partial blackbox can still be used.
  // We should always send the blackbox even if "complete" is still false
  bbValue = bb;
  bbComplete = complete;
};

export const initIovation = () => {
  // eslint-disable-next-line camelcase
  window.io_global_object_name = IOVATION_GLOBAL_NAME;
  window[IOVATION_GLOBAL_NAME] = window[IOVATION_GLOBAL_NAME] || {
    loader: {
      // `/iojs` is a proxy in Azure Front Door to Iovation's servers that allows scripts to be loaded from sbux domains.
      'uri_hook': '/iojs',
      version: 'general5',
    },
    'bb_callback': handleBlackBox,
  };
  // It is critical that the configuration comes before the iovation script
  // otherwise configuration variables will not be used once the script starts running.
  return loadScript(`${assetsUrl}/iovation-first-third.js`);
};
