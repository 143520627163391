import { useEffect, useState } from 'react';
import {
  getDemystifyMenuFeatureFlag,
  getDemystifyMenuPdpFeatureFlag,
} from 'shared/app/utils/feature-flags';

const useDemystifyMenuFeatureFlag = () => {
  const [flags, setFlags] = useState({
    isDemystifyMenuEnabled: false,
    isDemystifyMenuPdpEnabled: false,
  });

  const fetchFeatureFlags = async () => {
    const [menuFlag, pdpFlag] = await Promise.all([
      getDemystifyMenuFeatureFlag(),
      getDemystifyMenuPdpFeatureFlag(),
    ]);

    setFlags({
      isDemystifyMenuEnabled: menuFlag,
      isDemystifyMenuPdpEnabled: pdpFlag,
    });
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  return flags;
};

export default useDemystifyMenuFeatureFlag;
