import { Children, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import {
  allSessionsExpiredSelector,
  onlyExtendedSessionFoundSelector,
} from '../../bundles/user';
import { doLogin } from '../../shell/state/action-creators/app-lifecycle';
import { useSessionCheckContext } from '../../shell/lib/session-check-provider';
import { getSigninUrlWithReturnUrl } from '../../utils/redirect-to-signin-with-return-url';

const SessionCheck = ({ children }) => {
  const dispatch = useDispatch();
  const { checkReAuthRequired } = useSessionCheckContext();
  const allSessionsExpired = useSelector(allSessionsExpiredSelector);
  const onlyExtendedSessionFound = useSelector(
    onlyExtendedSessionFoundSelector
  );

  useEffect(() => {
    if (allSessionsExpired) {
      dispatch(doLogin());
    }
  }, [allSessionsExpired]);

  if (allSessionsExpired) {
    return null;
  }

  useEffect(() => {
    if (onlyExtendedSessionFound) {
      // Delaying the re-auth dialog to prevent from race condition
      // when a bottomsheet or overlay is opened
      setTimeout(
        () =>
          checkReAuthRequired({
            nextAction: () => push(),
            onCancelRedirectUrl: getSigninUrlWithReturnUrl(),
          }),
        200
      );
    }
  }, [onlyExtendedSessionFound]);

  if (onlyExtendedSessionFound) {
    return null;
  }

  return children ? Children.only(children) : null;
};

export default SessionCheck;
